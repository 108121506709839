.decision-logo {
    max-width: 80%;
    margin-bottom: 1em;
}

.vertical-align {
    flex-direction: column;
    align-items: center; /* optional, if you want to center the items horizontally */
}

.entry-field[name="firstName"],
.entry-field[name="lastName"],
.entry-field[name="email"] {
    /* Add your custom styles here */
    border: 2px solid #000; /* Example border style */
    border-radius: 0px;
    padding: 10px;
}

.entry-field[name="firstName"]:focus,
.entry-field[name="lastName"]:focus,
.entry-field[name="email"]:focus {
    box-shadow: 0 0 0 0.25rem #888888;
    border: 2px solid rgba(0,0,0,1) !important; /* Example border style */
}

.form-check-input[type="radio"] {
    border-radius: 0px !important;
}

input[name="role"]:focus {
    box-shadow: 0 0 0 0.25rem #888888;
}

.form-check-input[type="radio"]:checked {
    background-color: #000;
    border-color: #000;
}

.submit-button[type="submit"] {
    color: black;
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 0px;
    max-width: 60%;
    max-height: 60%;
    justify-self: end;
}

.submit-button[type="submit"]:hover {
    background-color: black;
    color: white;
    border-color: black;
}

.decision-signup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    }

.first-name-group { grid-area: 1 / 1 / 2 / 2; }
.last-name-group { grid-area: 1 / 2 / 2 / 3; }
.email-group { grid-area: 2 / 1 / 3 / 3; }
.radio-group { grid-area: 3 / 1 / 4 / 3; }
.submit-button[type="submit"] { grid-area: 4 / 2 / 5 / 3; }

/* This CSS rule sets the card-body to a constant size */
.card-body {
    width: 50%; /* Adjust this value to your desired constant width */
    margin: 0 auto; /* Center the card-body horizontally */
    margin-top: 2em;
}

/* This CSS rule applies when the screen width is 768px or less (typical mobile devices) */
@media (max-width: 768px) {
    .card-body {
        width: 90%; /* Make it 90% of the screen width on mobile */
    }

    .submit-button[type="submit"] {
        max-width: 80%;
        max-height: 60%;
    }
}